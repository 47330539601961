import { useState } from 'react'
import { Stepper, Button, Group, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'

import Form from 'components/Form/Form'
import ProductsSettings from 'screens/Settings/Products/Products'
import ApiKey from 'screens/Settings/ApiKey/ApiKey'

import css from './Onboarding.module.css'

const OnboardingSteps = ({ data, onSave, getData }) => {
  const [account, setAccount] = useState(data.account)

  let currentStep = 0
  if (account.companyName) currentStep = 1
  if (account.billingAddress) currentStep = 2
  if (account.invoice) currentStep = 3
  if (account.productCount) currentStep = 4
  if (account.edge?.apiKey) currentStep = 5

  const [active, setActive] = useState(currentStep)
  const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current))
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current))

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: account,
  })

  const onSubmit = async (data) => {
    const account = await onSave(data)
    setAccount(account)
    nextStep()
  }

  const legalNamesForm = [
    { header: 'Legal names' },
    { label: 'Company Name', name: 'companyName' },
    { label: 'Website', name: 'website' },
    { label: 'Phone', name: 'phone', optional: true },
  ]

  const addressForm = [
    { header: 'Billing address' },
    { label: 'City', name: 'billingAddress.city' },
    { label: 'Street', name: 'billingAddress.street' },
    { label: 'State', name: 'billingAddress.state' },
    { label: 'Post code', name: 'billingAddress.postalCode' },
    { label: 'Country', name: 'billingAddress.country' },
    { header: 'Shipping address' },
    { label: 'City', name: 'shippingAddress.city' },
    { label: 'Street', name: 'shippingAddress.street' },
    { label: 'State', name: 'shippingAddress.state' },
    { label: 'Post code', name: 'shippingAddress.postalCode' },
    { label: 'Country', name: 'shippingAddress.country' },
  ]

  const financeForm = [
    { header: 'Finance' },
    { label: 'Currency', name: 'currency', type: 'Select', data: ['USD', 'GBP', 'EUR'] },
    { label: 'Invoice Prefix', placeholder: '[XXXINVOICEPREFIXXX]-123213', name: 'invoice.prefix' },
    { label: 'Invoice primary language', name: 'invoice.language', type: 'Select', data: ['EN', 'LT'] },
    {
      label: 'VAT',
      description: 'In percents (%)',
      name: 'vat',
      type: 'NumberInput',
      validate: {
        type: 'isInRange',
        min: 0,
        max: 50,
        message: 'VAT should be between 0 and 50?',
      },
    },
  ]

  const onCompleted = async () => {
    const { account } = await getData()
    setAccount(account)
  }

  return (
    <div className={css.content}>
      <Stepper active={active} onStepClick={setActive} allowNextStepsSelect={false}>
        <Stepper.Step label="Legal names" description="Company details">
          <div className={css.step}>
            <Form data={account} inputs={legalNamesForm} onSubmit={onSubmit} key="legalNames">
              <Group justify="center" mt="xl">
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
                <Button type="submit">Submit</Button>
              </Group>
              <pre>{JSON.stringify(account, 2, 2)}</pre>
            </Form>
          </div>
        </Stepper.Step>
        <Stepper.Step label="Address" description="Billing, shippipng address">
          <div className={css.step}>
            <Form data={account} inputs={addressForm} onSubmit={onSubmit} key="address">
              <Group justify="center" mt="xl">
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
                <Button type="submit">Submit</Button>
              </Group>
            </Form>
          </div>
        </Stepper.Step>
        <Stepper.Step label="Finance" description="Currency, VAT rate">
          <div className={css.step}>
            <Form data={account} inputs={financeForm} onSubmit={onSubmit}>
              <Group justify="center" mt="xl">
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
                <Button type="submit">Submit</Button>
              </Group>
            </Form>
          </div>
        </Stepper.Step>

        <Stepper.Step label="Products on sale" description="Upload product list">
          <div className={css.step}>
            <h2>Products on sale</h2>
            <ProductsSettings {...account} onCompleted={onCompleted} />

            <Group justify="center" mt="xl">
              <Button variant="default" onClick={prevStep}>
                Back
              </Button>
              <Button type="submit" onClick={nextStep} disabled={!account.productCount}>
                Next
              </Button>
            </Group>
          </div>
        </Stepper.Step>

        <Stepper.Step label="Integration" description="Get full access">
          <div className={css.step}>
            <h2>Integration</h2>

            <ApiKey edge={account.edge} onCompleted={onCompleted} />
            <Group justify="center" mt="xl">
              <Button variant="default" onClick={prevStep}>
                Back
              </Button>
              <Button type="submit" onClick={nextStep}>
                Next
              </Button>
            </Group>
          </div>
        </Stepper.Step>

        <Stepper.Completed>
          <div className={css.step}>
            <h2>All done!</h2>
            <Group justify="center" mt="xl">
              <Button variant="default" onClick={prevStep}>
                Back
              </Button>
              <Button type="submit" onClick={() => onSubmit({ status: 'completed' })}>
                Finish
              </Button>
            </Group>
          </div>
        </Stepper.Completed>
      </Stepper>
    </div>
  )
}

export default OnboardingSteps
