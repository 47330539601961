import cn from 'classnames'

import css from './StatusSections.module.css'

const StatusSections = ({ list, status }) => {
  let found

  return (
    <ul className={css.bar}>
      {list.map((item) => {
        if (item.status === status) found = true

        return (
          <li key={item.status} className={cn(!found && css.isDone, item.status === status && css.isCurrent)}>
            <div>{item.label}</div>
          </li>
        )
      })}
    </ul>
  )
}

export default StatusSections
