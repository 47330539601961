import { PageHeader } from '../../components/page-header'

import ProductsTable from './ProductsTable'

const Products = (props) => {
  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Management', href: '/management' }, { label: 'Products' }]

  return (
    <div className="container2">
      <PageHeader title="Products" breadcrumbs={breadcrumbs}></PageHeader>
      <ProductsTable {...props} />
    </div>
  )
}

export default Products
