import { forwardRef, ReactNode, useEffect } from 'react'
import { Box, BoxProps, Flex, Container } from '@mantine/core'
import { nprogress } from '@mantine/nprogress'

interface PageProps extends BoxProps {
  children: ReactNode
  meta?: ReactNode
  title: string
}
export const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', meta, ...other }, ref) => {
  useEffect(() => {
    nprogress.complete()
    return () => nprogress.start()
  }, [])

  return (
    <>
      <Container fluid ref={ref} {...other}>
        {children}
      </Container>
    </>
  )
})
