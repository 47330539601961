import { Link } from 'react-router-dom'
import { ActionIcon, Badge, Avatar } from '@mantine/core'
import { IconTrashX } from '@tabler/icons-react'
import { formatDistance } from 'date-fns'

import { PageHeader } from '../../components/page-header'
import Preload, { usePreload } from '../../components/PreLoad/PreLoad'
import Table from '../../components/Table/Table'

import sales from '../../api/sales'

const Requests = () => {
  const resolve = usePreload(sales.getEnquiries)
  const { data, onRetry } = resolve

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Management', href: '/management' },
    { label: 'Requests' },
  ]

  const statusColors = {
    sned: 'blue',
    received: 'orange',
    orderCreated: 'green',
  }

  const columns = [
    {
      title: 'Customer',
      render: ({ customer: { logo, name } }) => (
        <div className="flex">
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={logo} alt={`Logo ${name}`} />
          <b>{name}</b>
        </div>
      ),
    },
    {
      title: 'Rep',
      render: ({ createdBy: { email, firstName, lastName } }) => (
        <div>
          <b>
            {firstName} {lastName}
          </b>
          <div>
            <small>{email}</small>
          </div>
        </div>
      ),
    },
    {
      title: 'Created at',
      render: ({ createdAt }) => formatDistance(createdAt, new Date()) + ' ago',
    },
    {
      title: 'Status',
      textAlign: 'center',
      render: ({ status }) =>
        status && (
          <Badge size="sm" color={statusColors[status]}>
            {status}
          </Badge>
        ),
    },
    {
      title: 'Conversations',
      textAlign: 'center',
      id: 'conversation.length',
    },
    {
      title: 'Items in basket',
      textAlign: 'center',
      id: 'basket.length',
    },
    {
      render: ({ _key }) => <Link to={'/o2c/requests-for-quote/' + _key}>Open</Link>,
    },
    {
      render: ({ _key }, { showConfirm }) => (
        <ActionIcon
          variant="light" //
          color="red"
          onClick={() => showConfirm(() => onRetry(() => sales.deleteEnquiry(_key)))}
        >
          <IconTrashX size={18} stroke={1.5} />
        </ActionIcon>
      ),
    },
  ]

  const filter = {
    key: 'status',
  }

  return (
    <Preload {...resolve} className="container2">
      <PageHeader title="Requests for quote" breadcrumbs={breadcrumbs} />
      <Table data={data} columns={columns} filter={filter} />
    </Preload>
  )
}

export default Requests
