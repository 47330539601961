import { PiBankDuotone, PiFileTextDuotone, PiGiftDuotone, PiPulseDuotone } from 'react-icons/pi'
import { LiaSeedlingSolid } from 'react-icons/lia'
import { RiUserHeartFill } from 'react-icons/ri'
import { alpha, Button, Group, SimpleGrid, Stack } from '@mantine/core'
import { MetricCard } from 'components/metric-card'
import { formatCurrency } from 'utilities/number'

const data = [
  { month: 'January', sales: 1200, orders: 19, rfqs: 25, aov: 58, atc: 79, newLeads: 20, newCustomers: 10 },
  { month: 'February', sales: 1900, orders: 24, rfqs: 48, aov: 66, atc: 76, newLeads: 3, newCustomers: 12 },
  { month: 'March', sales: 400, orders: 37, rfqs: 24, aov: 75, atc: 81, newLeads: 13, newCustomers: 10 },
  { month: 'April', sales: 1000, orders: 29, rfqs: 35, aov: 41, atc: 74, newLeads: 4, newCustomers: 11 },
  { month: 'May', sales: 800, orders: 31, rfqs: 34, aov: 50, atc: 70, newLeads: 3, newCustomers: 1 },
  { month: 'June', sales: 750, orders: 34, rfqs: 42, aov: 55, atc: 67, newLeads: 9, newCustomers: 2 },
  { month: 'July', sales: 800, orders: 37, rfqs: 40, aov: 60, atc: 70, newLeads: 13, newCustomers: 9 },
  { month: 'August', sales: 700, orders: 34, rfqs: 42, aov: 58, atc: 70, newLeads: 13, newCustomers: 11 },
  { month: 'September', sales: 750, orders: 32, rfqs: 40, aov: 65, atc: 63, newLeads: 18, newCustomers: 17 },
  { month: 'October', sales: 550, orders: 38, rfqs: 44, aov: 55, atc: 70, newLeads: 23, newCustomers: 21 },
]

const metrics = [
  {
    title: 'Leads and Customers',
    description: '21 new customers acquired this week',
    kpis: [
      {
        label: 'New leads',
        value: 27,
        icon: <LiaSeedlingSolid size="60%" color="orange.9" />,
        bgColor: 'orange.3',
      },
      {
        label: 'New customers',
        value: 21,
        icon: <RiUserHeartFill size="60%" color="blue.9" />,
        bgColor: 'blue.3',
      },
    ],
    selectedSeries: [
      { name: 'newLeads', color: 'orange.3' },
      { name: 'newCustomers', color: 'blue.3' },
    ],
  },
  {
    title: 'Sales pipeline',
    description: '30% acquired this week',
    kpis: [
      {
        label: 'Active Orders',
        value: 38,
        icon: <PiBankDuotone size="60%" color="pink.9" />,
        bgColor: 'pink.3',
      },
      {
        label: 'RFQs',
        value: 44,
        icon: <PiBankDuotone size="60%" color="cyan.9" />,
        bgColor: 'cyan.3',
      },
    ],
    selectedSeries: [
      { name: 'rfqs', color: 'cyan.3' },
      { name: 'orders', color: 'pink.3' },
    ],
  },
]

export function MetricsAndListAndChart() {
  return metrics.map(({ title, description, kpis = [], selectedSeries }, i) => (
    <MetricCard.Root className="dashboard__metricCard" key={`mlcr${i}`}>
      <Stack gap="xl">
        <Group justify="space-between" align="flex-start">
          <div>
            <MetricCard.TextEmphasis>{title}</MetricCard.TextEmphasis>
            <MetricCard.TextMuted>{description}</MetricCard.TextMuted>
          </div>
          <Button variant="subtle" color="gray" size="xs">
            View all
          </Button>
        </Group>

        <SimpleGrid cols={2}>
          {kpis.map((kpi, j) => {
            return (
              <Group gap="xs" key={`mlcrg${i}${kpi.value}`}>
                <MetricCard.Icon bg={kpi.bgColor} w="2.50rem" h="2.50rem">
                  {kpi.icon}
                </MetricCard.Icon>
                <div>
                  <MetricCard.TextMuted>{kpi.label}</MetricCard.TextMuted>
                  <MetricCard.TextEmphasis>{kpi.value}</MetricCard.TextEmphasis>
                </div>
              </Group>
            )
          })}
        </SimpleGrid>

        <MetricCard.BarChart
          h={280}
          tickLine="x"
          tooltipAnimationDuration={200}
          withTooltip={false}
          dataKey="month"
          style={{ backgroundColor: 'white' }}
          series={selectedSeries}
          data={data}
        />
      </Stack>
    </MetricCard.Root>
  ))
}
