import { Link } from 'react-router-dom'
import { formatDistance } from 'date-fns'
import { Badge, Button } from '@mantine/core'

import css from './Customers.module.css'

const CustomerEnquiries = ({ enquiries }) => {
  return (
    <ul className={css.inbox}>
      {enquiries &&
        enquiries.map((item, key) => (
          <li key={key}>
            <span>{`${item.createdBy.firstName} ${item.createdBy.lastName} <${item.createdBy.email}>`}</span>
            <span>{formatDistance(item.createdAt, new Date())} ago</span>
            <span>{item?.conversation?.length + ' messages'}</span>
            <Link to={'/customers/enquiries/' + item._key} style={{ textDecoration: 'none' }}>
              <Button component="span" variant="light" size="xs">
                Open
              </Button>
            </Link>
            {item.status === 'send' && <Badge>NEW</Badge>}
          </li>
        ))}
    </ul>
  )
}

export default CustomerEnquiries
