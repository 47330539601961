import { Link } from 'react-router-dom'
import { Avatar, profileImageUrl } from '@mantine/core'
import { formatRelative, formatDistance } from 'date-fns'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import { PageHeader } from 'components/page-header'
import Table from 'components/Table/Table'

import sales from 'api/sales'

import css from './Account.module.css'

const UserAccount = () => {
  const resolve = usePreload(sales.getMyAccount)
  const { data } = resolve

  const {
    name,
    logo,
    description,
    users,
    industry,
    companyType,
    employeeRange,
    foundationDate,
    createdAt,
    website,
    linkedinProfile,
    hqLocation,
    activityDomain,
    businessPhoneNumber,
    businessWhatsapp,
  } = data || {}

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Settings', href: '/' }, //
    { label: 'Account' },
  ]

  const columns = [
    {
      title: 'Name',
      render: ({ profileImageUrl, firstName, lastName }) => (
        <div className="flex">
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={profileImageUrl} alt={firstName + ' ' + lastName} />

          {firstName + ' ' + lastName}
        </div>
      ),
    },
    { title: 'Email', id: 'email' },
    { title: 'Created at', render: ({ createdAt }) => formatRelative(createdAt, new Date()) },
  ]

  return (
    <Preload {...resolve} className={css.layout}>
      <PageHeader title="My Account" breadcrumbs={breadcrumbs} />

      <div className={css.details}>
        <header>
          <div className="flex">
            <Avatar variant="outline" radius="xl" color="orange" src={logo} alt={`Logo ${name}`} />
            <div>
              <h2>{name}</h2>
            </div>
          </div>
          <div className="desc">{description}</div>
        </header>

        <section>
          <div>
            <ul className={css.list}>
              <li>
                Website:{' '}
                <a href={website} target="_blank">
                  {website}
                </a>
              </li>
              <li>
                Industry: <b>{activityDomain}</b>
              </li>
              <li>
                Company type: <b>{companyType}</b>
              </li>
              <li>
                Employees: <b>{employeeRange}</b>
              </li>
              <li>
                Foundation Date: <b>{foundationDate}</b>
              </li>
              <li>
                Known since: <b>{createdAt && formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}</b>
              </li>
            </ul>
          </div>
          <div>
            <ul className={css.list}>
              <li>
                Address: <b>{hqLocation}</b>
              </li>
              <li>
                Phone Number: <b>{businessPhoneNumber}</b>
              </li>
              <li>
                Whatsapp: <b>{businessWhatsapp}</b>
              </li>
              <li>
                LinkedIn: <b>{linkedinProfile}</b>
              </li>
            </ul>
          </div>
        </section>
      </div>

      <h4>Users</h4>

      <Table data={Array.from(users || [])} columns={columns} />
    </Preload>
  )
}

export default UserAccount
