import { useState, useEffect, useRef } from 'react'

import Spinner from 'components/Spinner/Spinner'

export const usePreload = (asyncFn, key) => {
  const isCalledRef = useRef(false)
  const [getDataFn] = useState(() => asyncFn)

  const [data, setData] = useState(null)
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(null)
  const [updateFn, setUpdateFn] = useState()

  const onRetry = async (fn) => {
    setIsLoading(true)

    if (fn) {
      await fn()
    }
    const result = await getDataFn()
    setData(result)
    setIsLoading(false)
  }

  const onUpdate = async (fn) => {
    setIsLoading(true)

    if (fn) {
      const result = await fn()
      setData(result)
    }
    setIsLoading(false)
    return data
  }

  useEffect(() => {
    const noop = () => null
    const handlePromise = async () => {
      isCalledRef.current = true
      setIsLoading(true)

      try {
        const result = await (updateFn ? updateFn : (getDataFn && getDataFn()) || noop)
        setData(result)
      } catch (e) {
        console.error('-- ERROR --', e)
        setError(e)
      }

      setIsLoading(false)
    }

    if (!isCalledRef.current) handlePromise()
  }, [key, getDataFn])

  return { isLoading, data, error, onUpdate, onRetry }
}

export const Preload = ({ children, isLoading, data, className }) => {
  if (isLoading === null) return null

  if (isLoading && !data) return <Spinner />

  return (
    <div className={className} style={{ opacity: isLoading ? 0.5 : 1 }}>
      {children}
    </div>
  )
}

export default Preload
