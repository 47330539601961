import { formatRelative } from 'date-fns'
import { Avatar, Button, Anchor, Group } from '@mantine/core'

import { PageHeader } from '../../components/page-header'
import Preload, { usePreload } from '../../components/PreLoad/PreLoad'
import Table from '../../components/Table/Table'

import backend from '../../api/backend'

import CustomerEnquiries from './CustomerEnquiries'

const Customers = () => {
  const resolve = usePreload(backend.getCustomers)
  const breadcrumbs = [{ label: 'Home', href: '/' }, { label: 'Management', href: '/management' }, { label: 'Customers' }]

  const columns = [
    {
      title: 'Company',
      render: ({ logo, name, website }) => (
        <Group>
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={logo} alt={`Logo ${name}`} />
          <Anchor href={`${website}`} target="_blank">
            {name}
          </Anchor>
        </Group>
      ),
    },
    { title: 'About', id: 'shortDescription' },
    { title: 'Created at', render: ({ createdAt }) => formatRelative(createdAt, new Date()) },
    { title: 'Enquiries', textAlign: 'center', id: 'enquiries.length' },
    {
      render: (row, { showContent }) => (
        <Button size="xs" variant="transparent" onClick={() => showContent((v) => !v)}>
          Expand
        </Button>
      ),
      content: ({ enquiries}) => <CustomerEnquiries enquiries={enquiries} />,
    },
  ]

  const filter = {
    key: 'rfqCount',
  }

  const { data } = resolve
  return (
    <Preload {...resolve} className="container2">
      <PageHeader title="Customers" breadcrumbs={breadcrumbs} />
      <Table data={data} columns={columns} filter={filter} />
    </Preload>
  )
}

export default Customers
