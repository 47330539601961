import AllUsers from './AllUsers/AllUsers'
import AdminAccounts from './Accounts/Accounts'
import AdminAccountPage from './Accounts/Page/AccountPage'

const adminRoutes = [
  {
    path: '/admin/allusers',
    element: <AllUsers />,
    permissions: 'admin',
  },
  {
    path: '/admin/accounts',
    element: <AdminAccounts />,
    permissions: ['admin'],
  },
  {
    path: '/admin/accounts/:id',
    element: <AdminAccountPage />,
    permissions: ['admin'],
  },
]

export default adminRoutes
