import { Button, Group, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'

import admin from 'api/admin'

import css from './InviteUser.module.css'

const InviteUser = ({ onRetry }) => {
  const [open, setOpen] = useState()
  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
  })

  const onSubmit = (data) => {
    onRetry(() => admin.createUser(data))
    setOpen(false)
  }

  return open ? (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <div className={css.step}>
        <h2>Invite user</h2>

        <div className={css.form}>
          <div className={css.row}>
            <TextInput required label="Email" placeholder="Email" {...form.getInputProps('email')} />
          </div>

          <div className={css.row}>
            <TextInput required label="First name" placeholder="first name" {...form.getInputProps('firstName')} />
          </div>

          <div className={css.row}>
            <TextInput required label="Last name" placeholder="Last name" {...form.getInputProps('lastName')} />
          </div>
        </div>
      </div>
      <Group justify="center" mt="xl">
        <Button variant="default" onClick={() => setOpen(false)}>
          Back
        </Button>
        <Button type="submit">Submit</Button>
      </Group>
    </form>
  ) : (
    <div className={css.button}>
      <Button onClick={() => setOpen(!open)}>Invite user</Button>
    </div>
  )
}

export default InviteUser
