import { Accordion, Avatar, Badge, Group, Text, Button } from '@mantine/core'
import { Link } from 'react-router-dom'
import { IconChevronDown, IconMapPin, IconUsersGroup, IconInfoSquareRounded, IconMapPins } from '@tabler/icons-react'
import { formatDistance } from 'date-fns'
import cn from 'classnames'
import Preload, { usePreload } from '../PreLoad/PreLoad'
import sales from 'api/sales'
import css from './CompanyAside.module.css'

const CompanyAside = ({ customer }) => {
  const {
    _key,
    logo,
    companyType,
    employeeRange,
    foundationDate,
    hqLocation,
    city,
    website,
    name,
    estimatedARR,
    industry,
    createdAt,
    businessPhoneNumber,
    description,
    representatives,
  } = customer

  const resolve = usePreload(() => sales.getCustomerInfo(_key))
  const customerInfo = resolve.data as any

  if (!customerInfo) return

  const { shippingAddress = {} } = customerInfo

  return (
    <Preload {...resolve} className={css.layout}>
      <div className={css.header}>
        <h3 className="flex">
          <Avatar variant="outline" size="lg" src={logo} alt={`Logo ${name}`} />
          {name}
        </h3>
        <div className={css.subTitle}>{description}</div>
        <div className={css.city}>
          <IconMapPin />
          {hqLocation || city}
        </div>
      </div>

      <Accordion className="acordionAside" multiple={true} defaultValue={['details', 'shippingAddress', 'representatives', 'enquiries', 'orders']}>
        <Accordion.Item value="details">
          <Accordion.Control>
            <Group wrap="nowrap">
              <IconInfoSquareRounded size={20} stroke={1} />
              <Text>Details</Text>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              <li>
                Website: <a href="#">{website}</a>
              </li>
              <li>
                Industry: <b>{industry}</b>
              </li>
              <li>
                Company type: <b>{companyType}</b>
              </li>
              <li>
                Employees: <b>{employeeRange}</b>
              </li>
              <li>
                Foundation Date: <b>{foundationDate}</b>
              </li>
              <li>
                Estimated ARR: <b>{estimatedARR}</b>
              </li>
              <li>
                Known since: <b>{formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}</b>
              </li>
            </ul>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="shippingAddress">
          <Accordion.Control icon={<IconInfoSquareRounded size={20} stroke={1.5} />}>Shipping Address</Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              <li>
                City: <b>{shippingAddress.city}</b>
              </li>
              <li>
                Street: <b>{shippingAddress.line1}</b>
              </li>
              <li>
                Postcode: <b>{shippingAddress.postalCode}</b>
              </li>
              <li>
                Country: <b>{shippingAddress.country}</b>
              </li>
              <li>
                Phone nr: <b>{businessPhoneNumber}</b>
              </li>
            </ul>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="representatives">
          <Accordion.Control icon={<IconInfoSquareRounded size={20} stroke={1.5} />}>Shipping Address</Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              {Array.from(Object.values(representatives)).map(({ firstName, lastName, email }: any) => (
                <li key={email} className={cn(css.active)}>
                  <Avatar variant="outline" color="blue" name={firstName + ' ' + lastName} />
                  <div className={css.user}>
                    <div>
                      {firstName} {lastName}
                    </div>
                    <small>{email}</small>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="enquiries">
          <Accordion.Control icon={<IconInfoSquareRounded size={20} stroke={1.5} />}>
            <Group>
              Enquiries
              <Badge radius="md" color="teal">
                {customerInfo.numEnquiries}
              </Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              {Array.from(customerInfo.enquiries).map((enq: any) => {
                return (
                  <li key={`order-${enq._key}`}>
                    {formatDistance(enq.createdAt, new Date(), { addSuffix: true })}
                    <Link to={`/o2c/requests-for-quote/${enq._key}`}>
                      <Button size="compact-xs" variant="outline" color="var(--teal-600)">
                        Open {enq.status}
                      </Button>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="orders">
          <Accordion.Control icon={<IconInfoSquareRounded size={20} stroke={1.5} />}>
            <Group>
              Orders
              <Badge radius="md" color={customerInfo.numOrders ? 'orange' : 'gray'}>
                {customerInfo.numOrders}
              </Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <ul className={css.list}>
              {Array.from(customerInfo.orders).map((o: any) => {
                return (
                  <li key={`order-${o._key}`}>
                    {formatDistance(o.createdAt, new Date(), { addSuffix: true })}
                    <Button component={Link} to={`/o2c/orders/${o._key}`} size="compact-xs" variant="outline" color="var(--teal-600)">
                      Open {o.status}
                    </Button>
                  </li>
                )
              })}
            </ul>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Preload>
  )
}

export default CompanyAside
