import { Link } from 'react-router-dom'
import { Badge, Avatar, NumberFormatter } from '@mantine/core'
import { formatDistance } from 'date-fns'

import Preload, { usePreload } from '../../components/PreLoad/PreLoad'
import Table from '../../components/Table/Table'

import { PageHeader } from '../../components/page-header'
import sales from '../../api/sales'

const statusColors = {
  inProgress: 'blue',
  pending: 'orange',
  completed: 'green',
}

const Orders = () => {
  const resolve = usePreload(sales.getOrders)
  const { data } = resolve

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Orders' },
  ]

  const columns = [
    {
      title: 'Company',
      render: ({ customer: { logo, company } }) => (
        <div className="flex">
          <Avatar variant="outline" radius="xl" size="sm" color="orange" src={logo} alt={`Logo ${company}`} />
          <b>{company}</b>
        </div>
      ),
    },
    {
      title: 'Rep',
      render: (item) => (
        <div>
          <b>
            {item.order.createdBy.firstName} {item.order.createdBy.lastName}
          </b>
          <div>
            <small>{item.order.createdBy.email}</small>
          </div>
        </div>
      ),
    },
    {
      title: 'Created at',
      render: (item) => formatDistance(new Date(item.order.createdAt), new Date(), { addSuffix: true }),
    },
    {
      title: 'Status',
      textAlign: 'center',
      render: ({ order }) =>
        order.status && (
          <Badge size="sm" color={statusColors[order.status]}>
            {order.status}
          </Badge>
        ),
    },
    {
      title: 'Items in basket',
      textAlign: 'center',
      id: 'order.basket.length',
    },
    {
      title: 'Total order value',
      textAlign: 'right',
      render: ({ order }) => {
        const totalValue = order.basket.map((bi) => bi.product.sellingPrice * bi.quantity).reduce((res, el) => res + el, 0)
        return <NumberFormatter prefix="$ " value={totalValue} thousandSeparator />
      },
    },
    { render: ({ order }) => <Link to={`/o2c/orders/${order._key}`}>Open</Link> },
  ]

  return (
    <Preload {...resolve} className="container2">
      <PageHeader title="Orders" breadcrumbs={breadcrumbs} />
      <Table data={Array.from(data || []).flatMap((item) => item.orders.map((order) => ({ customer: item.customer, order })))} columns={columns} />
    </Preload>
  )
}

export default Orders
