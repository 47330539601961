import { Link } from 'react-router-dom'
import { Avatar, Button } from '@mantine/core'
import { IconEye, IconProgressCheck } from '@tabler/icons-react'
import dayjs from 'dayjs'

import { PageHeader } from 'components/page-header'
import { Preload, usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import InviteUser from './InviteUser'

import auth from 'api/auth'
import admin from 'api/admin'

import css from './AllUsers.module.css'

const breadcrumbs = [
  { label: 'Home', href: '/' }, //
  { label: 'Admin', href: '/admin' },
  { label: 'Users' },
]

const AllUsers = () => {
  const resolve = usePreload(admin.getUsers)
  const { data } = resolve

  const columns = [
    {
      render: ({ profileImageUrl, displayName }) => (
        <Avatar src={profileImageUrl || '/user.jpg'} alt={displayName} radius="xl" size={24} w={24} color="violet" variant="subtle" />
      ),
    },
    {
      title: 'Full name',
      render: ({ displayName, account }) => (
        <div>
          <b>{displayName}</b>
          <div>{account?.name}</div>
        </div>
      ),
    },
    {
      title: 'Joined',
      render: ({ createdAt }) => <time>{dayjs(createdAt).format('DD/MM/YYYY HH:mm')}</time>,
    },
    {
      title: 'Email',
      id: 'email',
    },
    {
      title: 'Roles',
      render: ({ roles }) => (roles || []).join(', '),
    },
    {
      title: 'Providers',
      render: ({ providers }) => Object.keys(providers || []).join(', '),
    },
    {
      textAlign: 'right',
      render: ({ _key }) => (
        <Button component={Link} to={`/admin/users/${_key}`} leftSection={<IconEye size="1rem" stroke={1} />} variant="subtle" color="green" size="xs">
          view
        </Button>
      ),
    },
    {
      textAlign: 'right',
      render: ({ _key }) => (
        <Button
          onClick={async () => {
            const { jwt } = await admin.getUserJWT(_key)
            auth.setToken(jwt)
          }}
          leftSection={<IconProgressCheck size="1rem" stroke={1} />}
          variant="subtle"
          color="green"
          size="xs"
        >
          LogIn
        </Button>
      ),
    },
  ]

  const filter = {
    key: 'account.name',
  }

  return (
    <div>
      <PageHeader title="All users" breadcrumbs={breadcrumbs} />

      <Preload {...resolve}>
        <InviteUser {...resolve} />

        <Table data={data} columns={columns} filter={filter} />
      </Preload>
    </div>
  )
}

export default AllUsers
