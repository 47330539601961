import axios from 'axios'
import Cookies from 'js-cookie'
import app from './app'

class Http {
  constructor() {
    this.baseURL = window.APP_CONFIG && window.APP_CONFIG.endpoint
    this.axios = axios.create({ baseURL: this.baseURL })
    this.validToken = false
    // this.axios.defaults.baseURL = this.baseURL

    const jwt = Cookies.get('JWT')
    if (jwt) {
      this.setJwt(jwt)
      // delete this.axios.defaults.headers.common['authorization']
      this.axios.defaults.headers.common['authorization'] = `JWT ${jwt}`
      this.validToken = true
    }
    this.setInterceptors()
  }

  hasValidToken() {
    return this.validToken && Cookies.get('JWT')
  }

  setJwt(jwt) {
    if (jwt) {
      try {
        Cookies.set('JWT', jwt, { sameSite: 'strict', secure: true })
        this.axios.defaults.headers.common['authorization'] = 'JWT ' + jwt
        this.validToken = true
        return true
      } catch (e) {
        this.logout()
      }
    }
    // this.logout()
  }

  logout() {
    delete this.axios.defaults.headers.common['authorization']
    this.validToken = false
    Cookies.remove('JWT')
  }

  setInterceptors = () => {
    this.axios.interceptors.response.use(
      (response = {}) => {
        const { data } = response
        return data
      },
      async (error) => {
        const { response = {}, message, config = {} } = error
        let { status, data } = response
        const { url, method, baseURL } = config

        if (data && data.type === 'application/json') {
          try {
            const text = await data.text()
            data = JSON.parse(text)
          } catch (e) {
            console.error('Response is not JSON', response)
          }
        }

        const { baseUrl } = data || {}

        if (status === 403) {
          app.modal = {
            header: data.message || '403 Forbidden',
          }
        }

        if (data.message) {
          app.modal = {
            header: data.message,
          }
        }

        const { exception, stacktrace, errorMessage } = data
        app.modal = app.modal || {
          header: message,
          text: errorMessage || ['URL: ' + url, 'Method: ' + method, 'Base URL: ' + baseURL],
        }
        return Promise.reject({ status, message, errorMessage, error, url, method, baseURL, data })
      }
    )
  }

  //-------------------------------------------------------------------------------------------
  // For API documentation
  //-------------------------------------------------------------------------------------------
  create(baseURL) {
    const instance = axios.create()
    // const jwt = Cookies.get('JWT')
    instance.defaults.baseURL = baseURL || this.baseURL
    this._instance = instance
    return instance
  }

  get instance() {
    return this._instance || this.create()
  }
  //-------------------------------------------------------------------------------------------
}

const http = new Http()
export default http
