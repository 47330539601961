import get from 'lodash/get'
import cn from 'classnames'
import css from '../Table.module.css'

const TableFilter = ({ data, filter, onUpdate, selected }) => {
  const group = data.reduce((buf, item) => {
    const name = get(item, filter.key)
    buf[name] = buf[name] || []
    buf[name].push(item)
    return buf
  }, {})

  console.log(123, group)
  return (
    <div>
      <ul className={css.filter}>
        <button onClick={() => onUpdate(data)} className={cn(!selected && css.selected)}>
          All
          <span className={css.total}>{data.length}</span>
        </button>

        {Object.entries(group).map(([label, list]) => (
          <button key={label} onClick={() => onUpdate(list, label)} className={cn(selected === label && css.selected)}>
            {label === 'undefined' ? 'N/A' : label}
            <span className={css.total}>{list.length}</span>
          </button>
        ))}
        <li></li>
      </ul>
    </div>
  )
}

export default TableFilter
