import http from './http'

class SalesApi {
  constructor() {
    this.client = http.axios
  }
  getEnquiries = () => this.client.get('/sales/enquiries')
  getEnquiriesForCustomer = (customerId) => this.client.get('/sales/enquiries/customer/' + customerId)

  getEnquiry = (id) => this.client.get('/sales/enquiries/' + id)
  deleteEnquiry = (id) => this.client.delete('/sales/enquiries/' + id)

  postEnquiry = (body, apiKey = '') => this.client.post('/sales/enquiries/' + apiKey, body)

  replyToEnquiry = (id, body) => this.client.put('/sales/enquiries/' + id, body)
  customerReplyToEnquiry = (id, body) => this.client.put('/sales/enquiries/' + id + '/customer-reply', body)

  getCustomerByID = (id) => this.client.get('/sales/customers/' + id)
  getCustomerInfo = (id) => this.client.get(`/sales/customers/${id}/info`)

  query = (body) => this.client.post('/sales/products/query', body)
  createOrder = (enquiryId, body) => this.client.post('/sales/enquiries/' + enquiryId + '/create-order', body)
  getOrders = () => this.client.get('/sales/orders')
  getOrder = (orderId) => this.client.get('/sales/orders/' + orderId)

  deleteProducts = () => this.client.delete('/sales/products')

  getMyAccount = () => this.client.get('./sales/accounts/my')
  getAccountByKey = (apiKey) => this.client.get('/sales/accounts/' + apiKey)
  updateMyAccount = (data) => this.client.put('./sales/accounts/my', data)

  createApiKey = () => this.client.post('/sales/accounts/create-api-key')

  upload = (url, file, extra) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('fileName', file.name)

    if (extra) {
      Object.entries(extra).forEach(([name, value]) => {
        formData.append(name, JSON.stringify(value))
      })
    }

    return this.client.post(url, formData, { headers: { 'content-type': 'multipart/form-data' } })
  }

  uploadProducts = (file, labels) => this.upload('/sales/products', file, { labels })
  getProductLabels = () => this.client.get('/sales/products/labels')
}

const sales = new SalesApi()
export default sales
