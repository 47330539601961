import { Group, Stack } from '@mantine/core'
import { MetricCard } from 'components/metric-card'
import { formatInt, formatPercentage } from 'utilities/number'

const metrics = [


  {
    title: 'New orders',
    value: 23,
    suffix: ' orders',
    color: 'var(--mantine-color-red-6)',
    trend: -18.45,
    percentage: 48,
  },
  {
    title: 'New Customers',
    value: 21,
    suffix: ' customers',
    color: 'var(--mantine-color-teal-6)',
    trend: -29.78,
    percentage: 54,
  },
  {
    title: 'New Leads',
    value: 27,
    color: 'var(--mantine-color-teal-6)',
    trend: 5.42,
    percentage: 5.42,
    suffix: ' leads',
  },
  {
    title: 'New RFQs',
    value: 44,
    suffix: ' RFQs',
    color: 'var(--mantine-color-teal-6)',
    trend: 1.67,
    percentage: 54,
  },
]

export function MetricsAndProgressBar() {
  return metrics.map(({ title, value, color, trend, percentage, suffix, prefix }) => (
    <MetricCard.Root key={title} className="dashboard__metricCard">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <MetricCard.TextMuted>{title}</MetricCard.TextMuted>
          <MetricCard.TextEmphasis mb="sm">{formatInt(value, { suffix, prefix })}</MetricCard.TextEmphasis>
          <MetricCard.TextTrend value={trend}>last month</MetricCard.TextTrend>
        </Stack>

        {/* <MetricCard.RingProgress size={92} label={formatPercentage(percentage, { precision: 0 })} sections={[{ value: percentage, color }]} /> */}
      </Group>
    </MetricCard.Root>
  ))
}
