import { useState, useEffect } from 'react'
import cn from 'classnames'

import { Group } from '@mantine/core'
import TableFilter from './Filter/Filter'
import TableRow from './Row/Row'
import TablePagging from './Pagging/Pagging'
import ActionTableHeader from './ActionHeader/ActionHeader'
import css from './Table.module.css'

//-----------------------------------------------------------------------------------------------
const Table = ({ data, columns, rowKey, sticky, filter, limit = 30, footer = true, displayExtendedMenu = false, children }) => {
  const [rows, setRows] = useState([].concat(data))
  const [page, setPage] = useState(0)
  const [hidden, setHidden] = useState({})

  const [selected, setSelected] = useState()
  const from = page * limit
  const displayRows = rows.slice(from, from + limit)

  useEffect(() => {
    setRows([].concat(data))
  }, [data])

  const onUpdate = (list, name) => {
    setSelected(name)
    setRows(list)
  }

  const visibleColumns = columns.filter((col) => !hidden[col.title])
  return (
    <div className={css.table}>
      <Group justify="space-between">
        {data && filter ? <TableFilter data={data} selected={selected} filter={filter} onUpdate={onUpdate} /> : <div></div>}
        {displayExtendedMenu ? <ActionTableHeader columns={columns} hiddenColumns={hidden} onColumnsChange={(c) => setHidden(c)} /> : null}
      </Group>

      <table>
        <thead className={cn(css.thead, sticky && css.sticky)}>
          <tr className={css.tr}>
            {visibleColumns.map((item, key) => (
              <th className={css.th} key={key}>
                <div className={css.header} style={{ justifyContent: item.textAlign }}>
                  {item.icon && <div>{item.icon}</div>}
                  {item.title}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={css.tbody}>
          {displayRows.map((row, index) => (
            <TableRow row={row} columns={visibleColumns} key={row[rowKey] || (page + 1) * index} />
          ))}
        </tbody>
        {children}
      </table>
      {footer && <TablePagging rows={rows} limit={limit} page={page} setPage={setPage} />}
    </div>
  )
}

//-----------------------------------------------------------------------------------------------
export default Table
