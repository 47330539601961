import { Outlet } from 'react-router-dom'
import { MantineProvider, createTheme } from '@mantine/core'
import { observer } from 'mobx-react'

import './Layout.utils.scss'
import './Layout.scss'

const Layout = () => {
  return (
    <MantineProvider theme={createTheme({ fontFamily: 'Inter, IBM Plex mono, sans-serif' })}>
      <div className="layout__public">
        <Outlet />
      </div>
    </MantineProvider>
  )
}

export default observer(Layout)
