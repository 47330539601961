import http from './http'

class ArtifactsApi {
  constructor() {
    this.client = http.axios
  }

  downloadPdf = ({ bodyHtml, footerHtml }) => this.client.post('/pdf/print', { bodyHtml, footerHtml }, { responseType: 'blob' })
}

export default new ArtifactsApi()
