import './AllBriefs.scss'

import { useEffect, useState } from 'react'
import { Container } from '@mantine/core'

import BriefListTable from 'components/BriefsList/BriefListTable'

import backend from 'api/backend'

const AllBriefs = () => {
  const [allBriefs, setAllBriefs] = useState(null)

  useEffect(() => {
    backend.adminGetAllBriefsForAllUsers().then((res) => {
      setAllBriefs(res)
    })
  }, [])

  if (!allBriefs) return null

  return (
    <Container size="80%">
      <BriefListTable items={allBriefs}></BriefListTable>
    </Container>
  )
}

export default AllBriefs
