import React, { useEffect } from 'react'
import { Box, Text, Title, Select, Radio, Stack, Group, SimpleGrid, Paper } from '@mantine/core'
import { useForm } from '@mantine/form'
import settings from 'api/settings'
import classes from './RFQAttributesForm.css'

const RFQAttributesForm = ({ onFormChange }) => {
  const rfqAttributes = settings.requestCategories().rfqAttributes

  const RfqForm = useForm({
    initialValues: Object.fromEntries(Object.values(rfqAttributes).map((v) => [v.id, v.defaultValue])),
  })

  useEffect(() => {
    onFormChange(RfqForm.values)
  }, [RfqForm.values, onFormChange])

  const renderOptions = (attributes) => {
    if (attributes.options.length <= 30) {
      return (
        <Radio.Group
          {...RfqForm.getInputProps(attributes.id)}
          defaultValue={attributes.defaultValue}
          label={attributes.description}
          // description="Choose a suitable option for your order."
        >
          <SimpleGrid cols={{ base: 1, sm: 2, md: 2, lg: 4, xl: 6 }}>
            {attributes.options.map((option, i) => {
              return (
                <Radio.Card className={classes.root} radius="md" value={option.value}  key={`rfarc-${i}`}>
                  <Group wrap="nowrap" p={'xs'}>
                    <Radio.Indicator />
                    <div>
                      <Text size="sm" fw={500}>
                        {option.label}
                      </Text>
                      <Text size="xs" fw={300}>
                        {option.description}
                      </Text>
                    </div>
                  </Group>
                </Radio.Card>
              )
            })}
          </SimpleGrid>
        </Radio.Group>
      )
    } else {
      return (
        <Select
          data={attributes.options.map((option) => ({ value: option.value, label: option.label }))}
          {...RfqForm.getInputProps(attributes.id)}
          placeholder="Select an option"
        />
      )
    }
  }

  return (
    <Stack>
      <Title order={5} mb="md">
        Delivery, payment and terms
      </Title>
      <form>
        {Object.values(rfqAttributes).map((attributes) => (
          <Paper p="xs" m="xs" withBorder key={attributes.id}>
            {renderOptions(attributes)}
          </Paper>
        ))}
      </form>
    </Stack>
  )
}

export default RFQAttributesForm
