import { useParams } from 'react-router-dom'

import { PageHeader } from '../../../components/page-header'
import Preload, { usePreload } from '../../../components/PreLoad/PreLoad'

import sales from '../../../api/sales'

import Conversations from '../../../components/Conversations/Conversations'

const breadcrumbs = [
  { label: 'Home', href: '/' }, //
  { label: 'Management', href: '/management' },
  { label: 'Customers', href: '/customers' },
  { label: 'email' },
]

const CustomerEmail = () => {
  const { id } = useParams()

  const resolve = usePreload(() => sales.getEnquiry(id))

  const { data, onRetry } = resolve
  const { status, customer } = data || {}

  const clientTurn = status === 'send'

  const onSubmit = (message) => {
    onRetry(() => sales.customerReplyToEnquiry(id, { message }))
  }

  return (
    <Preload {...resolve} className="container2">
      <PageHeader title={'Customer Email - ' + customer?.firstName + ' ' + customer?.lastName} breadcrumbs={breadcrumbs} />

      <Conversations enquiry={data} onSubmit={clientTurn && onSubmit} />
    </Preload>
  )
}

export default CustomerEmail
