import http from './http'
import { LRUCache } from 'lru-cache'
import Cookies from 'js-cookie'

// const delay = (ms) => new Promise((r) => setTimeout(() => r(ms), ms))

export class Backend {
  constructor() {
    this.client = http.axios
    this.cache = new LRUCache({
      max: 100, // max 100 items -- some are quite heavy so 100 seems appropriate for now
      ttl: 1000 * 60 * 30, // 30mins
      ttlResolution: 1000 * 60 * 30, // 30mins
      updateAgeOnGet: true,
      updateAgeOnHas: true,
      noDeleteOnFetchRejection: true,
      allowStaleOnFetchRejection: true,
      allowStale: true,

      fetchMethod: async (key, staleValue, { options, signal, context }) => {
        if (signal.aborted) {
          return staleValue
        }
        let res = null
        try {
          res = await this.client.get(key)
          // console.log('CACHE Size', this.cache.size, Array.from(this.cache.keys()))
          // this.cache.set(key, res)
        } catch (error) {
          console.error(`Failed to fetch data from ${key}:`, error)
          return staleValue
        }
        return res
      },
    })
  }

  getLeads = () => this.cache.fetch('/sales/leads')
  getProducts = () => this.client.get('/sales/products')

  getCustomers = () => this.client.get('/sales/customers')

  async getMyBriefs() {
    return await this.cache.fetch('/briefs/my')
  }

  ////////////////////////////////////////////////////////////////////
  adminGetBriefFull = (broadnID) => this.cache.fetch(`/emailagent/briefs/${broadnID}/full`)

  async getBriefResultFileMock(broadnID, index) {
    return this.client.get(`/admin/briefs/${broadnID}/files?index=${index}`)
  }

  async getBriefResultFile(broadnID, location) {
    try {
      const encodedLocation = encodeURIComponent(location)
      const response = await this.client.get(`/emailagent/briefs/${broadnID}/files?location=${encodedLocation}`, {
        validateStatus: function (status) {
          return status < 500 // Resolve only if the status code is less than 500
        },
      })

      return response
    } catch (err) {
      console.lor('Error @getBriefResultFile w/', broadnID, location)
      return null
    }
  }
  adminGetAllUsers = async () => {
    return this.cache.fetch(`/emailagent/allusers`)
  }

  adminGetAllBriefsForAllUsers = (id) => this.cache.fetch(`/emailagent/allbriefs`)

  async getUserPayments(forceRefresh = false) {
    return this.cache.fetch(`/emailagent/payments/transactions`, { forceRefresh })
  }

  async getMyUserWithMembership() {
    return this.cache.fetch(`/users/withMembership`)
  }

  async cancelStripeSubscription(subscription, paymentIntent) {
    this.client.post(`/emailagent/payments/cancelStripeSubscription`, { subscription, paymentIntent })
  }

  async adminGetUserTransactions(userid, forceRefresh = false) {
    return this.client.get(`/emailagent/users/${userid}/payments`)
  }
  async adminGetUserProfile(userid) {
    return this.cache.fetch(`/emailagent/users/${userid}`)
  }

  async updateAccountStatus(userid, status) {
    return this.client.post(`/emailagent/users/updateAccountStatus`, { _key: userid, accountStatus: status })
  }

  updateProfile = async (_key, data) => {
    return this.client.post(`/emailagent/users/updateAccountStatus`, { ...data, _key })
  }

  async getAvailablePlans() {
    return this.cache.fetch(`/emailagent/payments/availablePlans`)
  }

  async addGiftMembership(membership) {
    return this.client.post(`/emailagent/giftMemberships`, { operation: 'add', membership })
  }

  async removeGiftMembership(membership) {
    return this.client.post(`/emailagent/giftMemberships`, { operation: 'delete', membership })
  }

  async getGlobalConfig() {
    return this.client.get(`/admin/globalConfig`)
  }

  async setGlobalConfig(configObject) {
    return this.client.post('/admin/globalConfig', configObject)
  }

  executeBattleCard = (id) => this.client.get(`/search/battle-cards/${id}/execute`)

  async initiateGAuthForGmail() {
    const jwt = Cookies.get('JWT')
    window.location.replace(`${http.baseURL}/auth/google/wGmailInit?token=${jwt}`)
  }
}

const backend = new Backend()
export default backend
