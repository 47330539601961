import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IconChevronDown } from '@tabler/icons-react'
import cn from 'classnames'

import css from './MultiButton.module.css'

const MultiButton = ({ label, list, disabled }) => {
  const [open, setOpen] = useState()

  return (
    <div className={css.container}>
      <div className={cn(css.button, open && css.active)} disabled={disabled} onClick={() => !disabled && setOpen(!open)}>
        <div className={css.label}>{label}</div>
        <button className={css.toggle}>
          <IconChevronDown size={12} />
        </button>
      </div>

      {open && (
        <ul className={css.menu}>
          {list.map((item, key) => (
            <li key={key}>
              {item.link ? (
                <Link className={css.menuItem} to={item.link}>
                  {item.icon}
                  {item.label}
                </Link>
              ) : (
                <button
                  className={css.menuItem}
                  onClick={() => {
                    item.onClick()
                    setOpen(false)
                  }}
                >
                  {item.icon}
                  {item.label}
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default MultiButton
