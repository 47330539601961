import http from './http'

class AdminApi {
  constructor() {
    this.client = http.axios
  }
  getUser = (uid) => this.client.get('/admin/users/' + uid)
  getUsers = () => this.client.get('/admin/users')

  getAccounts = () => this.client.get('/admin/accounts')
  getAccountById = (accountId) => this.client.get('/admin/accounts/' + accountId)
  updateAccount = (uid, data) => this.client.put('/admin/accounts/users/' + uid, data)

  createUser = (user) => this.client.post('/admin/users', user)
  getUserJWT = (uid) => this.client.get('/admin/users/' + uid + '/login')
}

const admin = new AdminApi()
export default admin
