import Preload, { usePreload } from '../../components/PreLoad/PreLoad'
import Table from '../../components/Table/Table'
import AddToBasket from '../../components/Conversations/AddToBasket'
import { NumberFormatter } from '@mantine/core'

import backend from '../../api/backend'

const renderMap = {
  productCode: ({ productCode }) => <small className="rounded outline w-600">{productCode}</small>,
  sellingPrice: ({ sellingPrice, currency }) => (
    <NumberFormatter prefix={`${currency} `} value={sellingPrice} thousandSeparator decimalScale={2} fixedDecimalScale={false} />
  ),
  msrp: ({ msrp, currency }) => <NumberFormatter prefix={`${currency} `} value={msrp} thousandSeparator decimalScale={2} fixedDecimalScale={false} />,
}

const ProductsTable = ({ selected, onAddToBasket, limit }) => {
  const resolve = usePreload(backend.getProducts)

  const { data } = resolve
  const { labels, products } = data || {}

  let knownColumns = []
  let restColumns = []

  if (data) {
    let productLabels = products[0] ? Object.keys(products[0]).filter((name) => name[0] !== '_') : []

    knownColumns = labels
      .filter(({ value, hidden }) => {
        const found = productLabels.includes(value)
        if (found) {
          productLabels = productLabels.filter((name) => name !== value)
        }
        return found && !hidden
      })
      .map(({ value, label, hidden }) => {
        const col = {
          title: label,
          ellipsis: '200px',
        }
        const render = renderMap[value]
        if (render) {
          col.render = render
        } else {
          col.key = value
        }
        return col
      })

    restColumns = productLabels.map((name) => ({ title: name, key: name, ellipsis: '200px' }))
  }

  const columns = [
    ...knownColumns,
    ...restColumns,
    onAddToBasket && {
      title: 'Quantity',
      render: (product) => (
        <AddToBasket
          onPlus={onAddToBasket} //
          onMinus={onAddToBasket}
          selected={selected}
          product={product}
          canEdit={true}
        />
      ),
    },
  ].filter(Boolean)

  return <Preload {...resolve}>{data && <Table data={products} columns={columns} rowKey="_key" limit={limit} displayExtendedMenu={true} />}</Preload>
}

export default ProductsTable
