import { Outlet } from 'react-router-dom'
import { MantineProvider, createTheme } from '@mantine/core'
import { observer } from 'mobx-react'

import LayoutHeader from './Layout.header'
import LayoutNav from './Nav/Nav'

import './Layout.utils.scss'
import './Layout.scss'

const Layout = () => {
  return (
    <MantineProvider theme={createTheme({ fontFamily: 'Inter, IBM Plex mono, sans-serif' })}>
      <div className="layout">
        {/* <LayoutHeader /> */}
        <LayoutNav />
        <div className="layout__content">
          <Outlet />
        </div>
        <footer className="layout__footer">
          <div>Copyright © {new Date().getFullYear()} broadn Inc.</div>
          <div> All rights reserved.</div>
        </footer>
      </div>
    </MantineProvider>
  )
}

export default observer(Layout)
