import { useState } from 'react'
import { Button } from '@mantine/core'
import get from 'lodash/get'

import css from '../Table.module.css'

const TableCell = ({ row, column, setContent }) => {
  const [showOverlay, setShowOverlay] = useState()
  const { id, dataIndex, index, key, render, textAlign, ellipsis } = column

  const valueIndex = id || dataIndex || index || key
  const value = valueIndex === undefined ? row : get(row, valueIndex)

  let displayValue = value

  const showContent = (v) => {
    setContent((c) => v(c) && column.content)
  }

  const showConfirm = (fn) => {
    setShowOverlay((v) => (v ? false : fn))
  }

  if (render) {
    displayValue = render(value, { showContent, showConfirm })
  }

  const style = { textAlign }
  if (ellipsis) {
    style.maxWidth = ellipsis
  }

  return (
    <td className={css.td}>
      <div style={style} className={ellipsis && css.ellipsis}>
        {displayValue}
      </div>
      {showOverlay && <Overlay {...column} onClick={() => showOverlay(row)} onClose={() => setShowOverlay(false)} />}
    </td>
  )
}

const Overlay = ({ onClick, onClose }) => {
  const onConfirm = async () => {
    await onClick()
    onClose()
  }
  return (
    <div className={css.overlay}>
      <div className={css.overlayTitle}>Are you sure?</div>
      <div className={css.overlayActions}>
        <Button color="red" onClick={onConfirm}>
          Yes
        </Button>
        <Button variant="white" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  )
}
export default TableCell
