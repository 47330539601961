import { Stack, Group } from '@mantine/core'
import { MetricCard } from 'components/metric-card'
import { formatDecimal } from 'utilities/number'

const data = [
  { month: 'January', sales: 1200, orders: 900, rfqs: 200, aov: 58, atc: 79 },
  { month: 'February', sales: 1900, orders: 730, rfqs: 400, aov: 66, atc: 76 },
  { month: 'March', sales: 400, orders: 440, rfqs: 200, aov: 75, atc: 81 },
  { month: 'April', sales: 1000, orders: 200, rfqs: 350, aov: 41, atc: 74 },
  { month: 'May', sales: 800, orders: 1400, rfqs: 320, aov: 50, atc: 70 },
  { month: 'June', sales: 750, orders: 900, rfqs: 380, aov: 55, atc: 67 },
  { month: 'July', sales: 800, orders: 600, rfqs: 370, aov: 60, atc: 70 },
  { month: 'August', sales: 700, orders: 780, rfqs: 380, aov: 58, atc: 70 },
  { month: 'September', sales: 750, orders: 800, rfqs: 370, aov: 65, atc: 63 },
  { month: 'October', sales: 550, orders: 550, rfqs: 300, aov: 55, atc: 70 },
]

const metrics = [
  {
    id: 'sales',
    title: 'Sales / mo',
    description: 'Total monthly sales.',
    value: 90.8,
    prefix: '$ ',
    suffix: ' k',
    precision: 1,
    color: 'green.3',
    trend: -26.66,
    percentage: 54,
  },
  {
    id: 'orders',
    title: 'Active orders',
    description: 'No. orders received (mo).',
    value: 38,
    precision: 0,
    color: 'grape.3',
    trend: -18.45,
    percentage: 48,
  },
  {
    id: 'rfqs',
    title: 'No. active RFQs ',
    description: 'No. RFQs received (mo)',
    value: 92,
    precision: 0,
    color: 'orange.3',
    trend: 20.34,
    percentage: 80.3,
  },
  {
    id: 'aov',
    title: 'Average Order Value',
    description: 'Avg. order value (mo)',
    value: 4300,
    precision: 0,
    prefix: '$ ',
    color: 'cyan.3',
    trend: 14.44,
    percentage: 54,
  },

  {
    id: 'atc',
    title: 'Avg. time to cash',
    description: 'Avg. time to full payout (mo)',
    value: 70,
    precision: 0,
    suffix: ' days',
    color: 'red.3',
    trend: 14.44,
    percentage: 54,
  },
]

export function MetricsAndBarChart() {
  return metrics.map(({ id, title, value, description, color, suffix, prefix, precision = 1, trend, percentage }) => (
    <MetricCard.Root key={`mbc${id}`} className="dashboard__metricCard">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0} align="flex-start">
          <MetricCard.TextMuted>{title}</MetricCard.TextMuted>
          <MetricCard.TextEmphasis mb="sm">{formatDecimal(value, { prefix, suffix, precision })}</MetricCard.TextEmphasis>
          <MetricCard.TextMuted>{description}</MetricCard.TextMuted>
          <MetricCard.TextTrend value={trend}>last month</MetricCard.TextTrend>
        </Stack>

        <MetricCard.BarChart
          h="100%"
          w={72}
          tickLine="none"
          gridAxis="none"
          dataKey="month"
          withXAxis={false}
          withYAxis={false}
          withTooltip={false}
          barProps={{ barSize: 8, radius: 8 }}
          series={[{ name: id, color }]}
          data={data}
        />
      </Group>
    </MetricCard.Root>
  ))
}
