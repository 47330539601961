import 'reactflow/dist/style.css'
import './Brief.scss'

import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import ReactFlow, { MiniMap, Controls, Background, useNodesState, useEdgesState, useOnSelectionChange, ReactFlowProvider } from 'reactflow'

import { Group, Tabs, Grid, Divider, Space, Text, Stack, Container } from '@mantine/core'
import { IconMickeyFilled, IconUniverse } from '@tabler/icons-react'

import { observer } from 'mobx-react'
import { formatDistance } from 'date-fns'

import { resultsToGraph, graphFromTaskDef } from './Workflow/Utils'
import TaskDefNodeTypes from './Workflow/TaskDefNodeTypes'
import WFNodeType from './Workflow/WFNodeType'
import NodePanelForTaskDef from './Workflow/NodePanelForTaskDef'
import GeneralBriefView from './GeneralView'
import NodePanel from './Workflow/NodePanel'

import TaskDef, { S3RefTaskContext, S3RefTaskDef, TaskContext, WorkflowExecutionResult } from './Types'
import { BriefWithExecutionResults } from '../../common/BriefTypes'

import Spinner from '../../components/Spinner/Spinner'
import backend from '../../api/backend'

// import MockData from './full.json'

const executionRunNodeTypes = {
  wfNode: WFNodeType,
}
const taskDefNodeTypes = {
  wfNode: TaskDefNodeTypes,
}

interface OnboardingWF {
  type: 'onboarding' | 'wf2ExecRun'
  id: string
  refData: {
    taskDef: S3RefTaskDef
    taskContext: S3RefTaskContext
  }
  resolvedData: {
    taskContext?: TaskContext
    taskDef?: TaskDef
  }
}
interface ExecutionWF {
  id: string
  type: 'executionRun'
  result: {
    location: string
    createdAt: number
  }
}

const BriefPage = observer(() => {
  let allParams = useParams()

  let broadnIDParam = (allParams.broadnID || '').trim()
  if (!broadnIDParam && !(broadnIDParam.length > 20)) return null

  let [broadnID] = useState<string | null>(broadnIDParam)
  let [briefWResults, setBrief] = useState<BriefWithExecutionResults | null | undefined>(null)
  const [activeViewTab, setActiveViewTab]: any = useState<string | null>('general')
  const [allWorkflows, setAllWorkflows] = useState<{ [k: string]: ExecutionWF | OnboardingWF }>({})
  const [activeWFIDTab, setActiveWFIDTab]: any = useState<string | null>(null)
  let [workflowPerRun, setWorkflowPerRun] = useState<any | null | undefined>({})
  let [selectedNodeID, setSelectedNodeID] = useState<string | undefined | null>(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [hasResult, setHasResults]: any = useState<boolean>(false)

  let selectedNodeComponent: any | null | undefined = null
  const onNodeClick = (id: string) => {
    setSelectedNodeID(id)
  }

  useOnSelectionChange({
    onChange: ({ nodes }) => {
      if (nodes.length > 0 && nodes[0].id) setSelectedNodeID(nodes[0].id)
    },
  })

  useEffect(() => {
    if (!briefWResults) {
      backend.adminGetBriefFull(broadnID).then((res) => {
        // const res = MockData
        const briefWResults = res as any as BriefWithExecutionResults
        setBrief(briefWResults)

        const allWFTabs: { [k: string]: ExecutionWF | OnboardingWF } = {}

        if (Object.keys(allWorkflows).length === 0) {
          briefWResults.brief.conversation
            .reduce((buf: any, cur) => {
              const last = buf.length > 0 ? buf[buf.length - 1] : undefined
              if (S3RefTaskDef.is(cur.payload)) {
                if (!last || (last.refData.taskDef && last.refData.taskContext)) buf.push({ id: cur.id, refData: {}, type: 'onboarding', resolvedData: {} })
                buf[buf.length - 1].refData.taskDef = cur.payload
              }
              if (S3RefTaskContext.is(cur.payload)) {
                if (!last || (last.refData.taskDef && last.refData.taskContext)) buf.push({ id: cur.id, refData: {}, type: 'onboarding', resolvedData: {} })
                buf[buf.length - 1].refData.taskContext = cur.payload
              }
              if (WorkflowExecutionResult.is(cur.payload)) {
                buf.push({
                  id: cur.id,
                  version: cur.payload.version,
                  refData: { taskDef: cur.payload.taskDefRef, taskContext: cur.payload.taskContextRef },
                  resolvedData: {},
                  type: 'wf2ExecRun',
                })
              }

              return buf
            }, [] as OnboardingWF[])
            .filter((wf: OnboardingWF) => wf.refData.taskContext && wf.refData.taskDef)
            .forEach((wf: any) => (allWFTabs[wf.id] = wf))

          console.log('AllWFTabs', allWFTabs)
          briefWResults.runs.forEach((r) => {
            allWFTabs[r.executionResults._key] = {
              type: 'executionRun',
              id: r.executionResults._key,
              result: r.executionResults.result,
            }
          })

          setAllWorkflows(allWFTabs)
          setActiveWFIDTab(Object.keys(allWFTabs)[0])
        }
      })
    }
  }, [broadnID])

  useEffect(() => {
    setHasResults(false)
    if (briefWResults) {
      const selectedWF = allWorkflows[activeWFIDTab]
      // backend.getBriefResultFileMock(broadnID, activeTab).then((runResult) => {
      if (selectedWF && selectedWF.type === 'executionRun') {
        if (selectedWF.result) {
          backend.getBriefResultFile(broadnID, selectedWF.result.location).then((runResult) => {
            const graph = resultsToGraph(runResult, onNodeClick)
            const wfPR = { ...workflowPerRun }
            wfPR[activeWFIDTab] = graph
            setWorkflowPerRun(wfPR)
            setNodes(graph.nodes)
            setEdges(graph.edges)
            setHasResults(true)

            console.log({ graph, runResult })
            return
          })
        } else {
          console.log('This seems to be an old version of a BriefResult')
        }
      }

      if (selectedWF && (selectedWF.type === 'onboarding' || selectedWF.type === 'wf2ExecRun')) {
        console.log('SelectedWFTab', selectedWF)
        if (selectedWF.resolvedData && selectedWF.resolvedData.taskDef) {
          buildGraphFromTaskDef(selectedWF.resolvedData.taskDef as TaskDef)
        } else
          Promise.all([
            backend.getBriefResultFile(broadnID, selectedWF.refData.taskDef.location).then((taskDef) => {
              selectedWF.resolvedData.taskDef = taskDef as any as TaskDef
            }),
            backend.getBriefResultFile(broadnID, selectedWF.refData.taskContext.location).then((context) => {
              selectedWF.resolvedData.taskContext = context as any as TaskContext
            }),
          ])
            .then(() => {
              console.log('SelectedWF.resolved', selectedWF)
              if (selectedWF.resolvedData && selectedWF.resolvedData.taskDef) buildGraphFromTaskDef(selectedWF.resolvedData.taskDef as TaskDef)
              else {
                console.log('Cannot load TaskDef for currently selected WF', selectedWF)
              }
            })
            .catch((error) => {
              console.log('Error fetching context', error)
            })
      }
    }
  }, [briefWResults, activeWFIDTab])

  const buildGraphFromTaskDef = (taskDef: TaskDef) => {
    const graph = graphFromTaskDef(taskDef as any as TaskDef)
    setNodes(graph.nodes)
    setEdges(graph.edges)
    console.log({ graph, taskDef })

    setHasResults(true)
  }

  const activeTabChanged = (nextWFActiveTabID: string | null) => {
    setHasResults(false)
    setActiveWFIDTab(nextWFActiveTabID)
  }

  if (!briefWResults) {
    if (hasResult) return <Text ta="center">Cannot find Brief with id: {broadnIDParam}</Text>
    return <Spinner />
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const tabList: any[] = []

  Object.values(allWorkflows).forEach((kv: any, i) => {
    const label = kv.type === 'onboarding' ? `Onb #${i}` : kv.type === 'wf2ExecRun' ? `WF2ExecRun V ${kv.version}` : `ExecRun #${i}`
    tabList.push(
      <Tabs.Tab value={kv.id} key={i}>
        <Text size="xs">{label}</Text>
      </Tabs.Tab>
    )
  })

  if (selectedNodeID) {
    selectedNodeComponent = {
      data: nodes.find((n) => {
        return n.id === selectedNodeID
      })?.data,
      id: selectedNodeID,
    }
  }

  const tabsForExecutionRuns =
    tabList.length > 0 ? (
      <Container fluid>
        <Tabs variant="outline" className="broadnTab" color="pink" value={activeWFIDTab} onChange={activeTabChanged}>
          <Tabs.List>{tabList}</Tabs.List>

          {hasResult ? (
            <Tabs.Panel value={activeWFIDTab} className="tabStyle" h={'75vh'}>
              {activeWFIDTab ? (
                <Container fluid h={'75vh'}>
                  {/* <pre>{JSON.stringify(nodes, null, 2)}</pre> */}
                  <div style={{ height: '75vh' }}>
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      nodeTypes={allWorkflows[activeWFIDTab].type === 'executionRun' ? executionRunNodeTypes : taskDefNodeTypes}
                      snapGrid={[25, 25]}
                      fitView
                      minZoom={0.2}
                    >
                      <Controls />
                      <MiniMap />
                      <Background variant="dots" gap={12} size={1} />
                    </ReactFlow>
                  </div>
                </Container>
              ) : (
                <Text ta="center">The result for this run doesn't exist on the server.</Text>
              )}
            </Tabs.Panel>
          ) : (
            <Spinner />
          )}
        </Tabs>
      </Container>
    ) : null

  let { brief } = briefWResults

  const threadID = (brief?.threadMessages && brief?.threadMessages[0]?.threadID) || ''
  const createdAt = brief.createdAt || brief.created_at || new Date()
  return (
    <Container fluid style={{ height: '100%', boxSizing: 'border-box' }}>
      <Space h={'xl'}></Space>
      <Group wrap="nowrap" justify="space-between">
        <Stack gap={0}>
          <Text size="sm">{brief.subject || ''}</Text>
          <Text size="xs" c="dimmed">
            {brief.broadnID || ''}
          </Text>
          <Link target="_blank" to={`https://platform.openai.com/playground?thread=${threadID}`}>
            <Text size="xs" c="pink.6">
              {threadID}
            </Text>
          </Link>
        </Stack>

        <Stack gap={0}>
          <Text size="sm">{brief.from}</Text>
          <Text size="sm" c="dimmed" fw={400}>
            {brief.subject}
          </Text>
        </Stack>

        <Stack gap={0}>
          <Text size="xs">Last action: {formatDistance(createdAt, new Date(), { addSuffix: true })}</Text>
          <Text size="xs">Created: {formatDistance(createdAt, new Date())}</Text>
        </Stack>

        <Stack gap={0}>
          <Text size="xs">Messages: {brief?.threadMessages?.length || 'None'}</Text>
        </Stack>

        <Stack gap={0} w={265}>
          <Group>
            <Text size="xs">Ver: {brief.version}</Text>
            <Space></Space>
            <Text size="xs" c={brief.executionBrief ? (brief.executionBrief.status === 'confirmed' ? 'lime.7' : 'dark.3') : 'black'}>
              {brief.executionBrief ? brief.executionBrief.status || 'undefined' : 'undefined'}
            </Text>
          </Group>
          <Text size="xs">{threadID}</Text>
        </Stack>
      </Group>
      <Space h={'sm'}></Space>
      <Divider></Divider>
      <Space h={'sm'}></Space>

      <Tabs variant="outline" className="broadnTab" color="pink" value={activeViewTab} onChange={setActiveViewTab}>
        <Tabs.List>
          <Tabs.Tab value="general" leftSection={<IconMickeyFilled stroke={1} />}>
            General
          </Tabs.Tab>
          <Tabs.Tab value="workflow" leftSection={<IconUniverse stroke={1} />}>
            Workflow
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="general" className="tabStyle">
          <GeneralBriefView {...briefWResults}></GeneralBriefView>
        </Tabs.Panel>

        <Tabs.Panel value="workflow" className="tabStyle">
          <Container fluid>
            <Grid>
              <Grid.Col span={5}>
                {hasResult ? (
                  allWorkflows[activeWFIDTab].type === 'executionRun' ? (
                    <Container fluid>{NodePanel(selectedNodeComponent)}</Container>
                  ) : (
                    <Container fluid>
                      {NodePanelForTaskDef({
                        selectedNodeComponent,
                        taskContext: (allWorkflows[activeWFIDTab] as OnboardingWF).resolvedData.taskContext,
                      })}
                    </Container>
                  )
                ) : (
                  <Spinner />
                )}
                {/* <Spinner /> */}
              </Grid.Col>
              <Grid.Col span={7}>{tabsForExecutionRuns}</Grid.Col>
            </Grid>
          </Container>
        </Tabs.Panel>
      </Tabs>
    </Container>
  )
})

function FlowWithProvider(props) {
  return (
    <ReactFlowProvider>
      <BriefPage {...props} />
    </ReactFlowProvider>
  )
}

export default FlowWithProvider
