import { useParams } from 'react-router-dom'
import { Text, Button } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import { PageHeader } from 'components/page-header'
import { usePdf } from 'common/hooks/usePdf'

import sales from 'api/sales'

import css from './PdfPreview.module.css'

const PdfPreview = () => {
  const { orderId } = useParams()
  const { download, getCompiled, isLoading } = usePdf()

  const resolve = usePreload(() => sales.getOrder(orderId))
  const { data } = resolve

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Enquiry', href: '/enquiry' },
    { label: 'Order', href: '/o2c/orders/' + orderId },
    { label: 'Invoice' },
  ]

  const onSetRef = (ref) => {
    if (ref) {
      const el = getCompiled(data, 'order')
      ref.append(el)
    }
  }

  return (
    <Preload {...resolve} className="print-page">
      <PageHeader title={'Order Invoice - #' + orderId} breadcrumbs={breadcrumbs} className="print-invisible" />
      <div className={css.layout}>
        <section>
          <div className="print-invisible">
            <div className={css.section}>
              <Button
                disabled={isLoading} //
                variant="default"
                leftSection={<IconDownload size={12} />}
                size="compact-sm"
                radius="sm"
                onClick={() => download(data, 'order', { title: 'Purchase order: #' + orderId })}
              >
                <Text size="xs">Download PDF</Text>
              </Button>
            </div>
          </div>

          <div ref={onSetRef}></div>
        </section>
      </div>
    </Preload>
  )
}

export default PdfPreview
