import { useParams } from 'react-router-dom'
import EnquiryForm from './Form/Form'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import sales from 'api/sales'

import css from './Enquiry.module.css'

const EnquiryPublic = () => {
  const { apiKey } = useParams()

  const resolve = usePreload(() => sales.getAccountByKey(apiKey))

  const { data } = resolve
  return (
    <div className="container">
      <Preload {...resolve}>
        {data?._key ? (
          <EnquiryForm apiKey={apiKey} />
        ) : (
          <div className={css.notFound}>
            <h4>Account not found!</h4>
          </div>
        )}
      </Preload>
    </div>
  )
}

export default EnquiryPublic
