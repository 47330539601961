import css from './Modal.module.css'

const Modal = ({ children }) => {
  return (
    <dialog className={css.dialog} ref={(ref) => ref && ref.showModal()}>
      <div className={css.content}>{children}</div>
    </dialog>
  )
}

export default Modal
