import { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { Title, Loader, Text, JsonInput, Space, Button, Group, Stack, Select } from '@mantine/core'

import backend from 'api/backend'

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

const EditConfig = observer(() => {
  const [configItems, setConfigItems] = useState([])

  const [configDict, setConfigDict] = useState({})
  const [loading, { toggle, close }] = useDisclosure()

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: { configKey: '', configValue: '' },
    enhanceGetInputProps: (payload) => {
      return {}
    },
  })

  useEffect(() => {
    backend.getGlobalConfig().then((config) => {
      const configItems = config.map((c) => ({ label: c.name, value: c._key }))
      setConfigItems(configItems)
      const dict = {}
      config.forEach((el) => (dict[el._key] = el))
      setConfigDict(dict)
    })
  }, [])

  const handleSubmit = async (values) => {
    toggle()
    const withSetValues = { ...configDict[values.configKey], ...JSON.parse(values.configValue) }
    await backend.setGlobalConfig(withSetValues)
    await delay(200)
    close()
  }

  const handleSelectionChange = async (_value, option) => {
    const newVal = { ...configDict[_value], _key: null, _rev: null, _id: null }
    delete newVal._id
    delete newVal._key
    delete newVal._rev
    const safeValue = JSON.stringify(newVal, null, 2)

    form.setValues({ configKey: _value, configValue: safeValue })
  }

  return (
    <>
      <Group justify="center" grow>
        <Stack justify="center" style={{ minWidth: '720px', maxWidth: '80%', paddingTop: '2em' }}>
          <Space h="xl" />
          <Title order={3}>Edit config</Title>
          <Text c="red" fw={600}>
            Make sure you know what you're doing before changing anything...{' '}
          </Text>
          <form onSubmit={form.onSubmit(handleSubmit)} style={{ width: '100%', justifyContent: 'flex-end', flexDirection: 'column', display: 'flex' }}>
            <Select
              color="pink.9"
              style={{ outline: 'violet' }}
              label="Select config key"
              placeholder="Select config key"
              maxDropdownHeight="320"
              searchable
              data={configItems}
              key={form.key('configKey')}
              {...form.getInputProps('configKey')}
              onChange={handleSelectionChange}
            />

            <JsonInput
              label="Config"
              placeholder="Config object"
              validationError="Invalid JSON"
              key={form.key('configValue')}
              formatOnBlur
              autosize
              minRows={5}
              size="xs"
              {...form.getInputProps('configValue')}
            />
            <Space h="xs" />
            <Group>
              <Button variant="outline" color="grape.9" w="200px" type="submit" rightSection={loading ? <Loader size="xs" color="grape" type="bars" /> : null}>
                {loading ? 'Saving ...' : 'Save to db'}
              </Button>
            </Group>
          </form>
        </Stack>
      </Group>
      <Space h="xl" />
    </>
  )
})

export default EditConfig
