import { Button, Group, Card, Text, Stack, CopyButton, Tooltip, ActionIcon, rem } from '@mantine/core'
import { IconKey, IconCheck, IconCopy } from '@tabler/icons-react'

import sales from 'api/sales'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

const ApiKey = ({ edge, onCompleted = () => {} }) => {
  const resolve = usePreload()
  const { data, onUpdate } = resolve

  const { origin } = window.location

  const settings = data || edge

  const iframeSrc = settings && settings.apiKey ? `<iframe src="${origin}/form/${settings.apiKey}" width="100%" height="500px" style="border: none" />` : ''

  return (
    <Preload {...resolve}>
      <Group align="center" justify="space-around">
        <Button
          variant="default"
          size="sm"
          radius="sm"
          onClick={async () => {
            const result = await onUpdate(sales.createApiKey)
            onCompleted(result)
          }}
          leftSection={<IconKey stroke={1} size={16} />}
        >
          Generate new web integration script
        </Button>
      </Group>

      {settings?.apiKey && (
        <Stack gap="1em">
          <Stack gap="0">
            <Text size="sm" margin="sm" fw={500}>
              Script.ID:
            </Text>
            <Card padding="lg" radius="md" withBorder>
              <Group align="center" justify="center">
                <Text size="xs" fw={500} c="green.8">
                  {settings.apiKey}
                </Text>
              </Group>
            </Card>
          </Stack>
          <Stack gap="0">
            <Text size="sm" margin="sm" fw={500}>
              Intergration iFrame:
            </Text>
            <CopyButton value={iframeSrc} timeout={2000}>
              {({ copied, copy }) => (
                <Card padding="lg" radius="md" withBorder>
                  <Group align="start" justify="end" style={{ position: 'absolute', top: '8px', right: '8px' }}>
                    <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="top">
                      <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                        {copied ? <IconCheck style={{ width: rem(16) }} /> : <IconCopy style={{ width: rem(16) }} />}
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                  <Group align="center" justify="center">
                    <Text size="xs" fw={500} c="green.8">
                      <code>{iframeSrc}</code>
                    </Text>
                  </Group>
                </Card>
              )}
            </CopyButton>
          </Stack>
        </Stack>
      )}
    </Preload>
  )
}

export default ApiKey
