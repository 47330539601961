import { useParams } from 'react-router-dom'
import { Text, NumberFormatter } from '@mantine/core'
import { IconDownload, IconEye } from '@tabler/icons-react'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'
import { PageHeader } from 'components/page-header'
import { usePdf } from 'common/hooks/usePdf'

import CompanyAside from 'components/CompanyAside/CompanyAside'

import StatusSections from 'components/StatusSections/StatusSections'

import sales from 'api/sales'
import settings from 'api/settings'

import css from './OrderPage.module.css'
import MultiButton from 'components/MultiButton/MultiButton'

const OrderPreview = () => {
  const { orderId } = useParams()
  const { download, isLoading } = usePdf()

  const resolve = usePreload(() => sales.getOrder(orderId))
  const { data } = resolve
  const { basket, rfqOptions = {} } = data || {}

  let subTotal = 0

  basket?.forEach((item) => {
    item.total = item.quantity * item.product.sellingPrice
    subTotal += item.total
  })

  const tax = Math.ceil(subTotal * 0.15)
  const totalAmount = subTotal + tax

  const breadcrumbs = [
    { label: 'Home', href: '/' }, //
    { label: 'Enquiry', href: '/enquiry' },
    { label: 'Order' },
  ]

  const columns = [
    {
      title: 'Product Code',
      render: ({ product: { productCode } }) => <small className="rounded outline w-600">{productCode}</small>,
    },
    { title: 'Description', id: 'product.description' },
    {
      title: 'Unit Price',
      render: ({ product: { sellingPrice, currency } }) => <NumberFormatter size="sm" prefix={`${currency} `} value={sellingPrice} thousandSeparator />,
    },
    { title: 'Quantity', id: 'quantity', textAlign: 'center' },
    {
      title: 'Total',
      textAlign: 'right',
      render: ({ product: { sellingPrice, currency }, quantity }) => <NumberFormatter size="sm" prefix="$ " value={sellingPrice * quantity} thousandSeparator />,
    },
  ]

  const statusList = [
    { status: 'draft', label: 'Draft' },
    { status: 'quoteConfirmed', label: 'Quote Confirmed' },
    // { status: 'pendingClientReview', label: 'Pending client review' },
    { status: 'POcreated', label: 'PO created' },
    // { status: 'POAcceptance', label: 'PO Acceptance' },
    // { status: 'manufacturing', label: 'Manufacturing' },
    { status: 'shipping', label: 'Shipping' },
    { status: 'completed', label: 'Completed' },
  ]

  const buttons = [
    { label: 'View PO', link: '/o2c/orders/' + orderId + '/invoice', icon: <IconEye size={12} /> },
    { label: 'Download PDF', onClick: () => download(data, 'order', { title: 'Purchase order: #' + orderId }), icon: <IconDownload size={12} /> },
  ]

  return (
    <Preload {...resolve} className="container2">
      <PageHeader title={'Order Preview - ID' + orderId} breadcrumbs={breadcrumbs} />
      <div className={css.layout}>
        <aside>
          <CompanyAside {...data} />
        </aside>

        <section>
          <div className={css.section}>
            <MultiButton list={buttons} label="Purchase Order" disabled={isLoading} />

            <StatusSections list={statusList} status="quoteConfirmed" />
          </div>

          <div className={css.order}>
            <h4>Order:</h4>
            <Table data={basket} columns={columns} footer={false}>
              <tbody>
                <tr>
                  <td colSpan={4} style={{ textAlign: 'right' }}>
                    <Text size="sm" fw={500}>
                      Gross Amount
                    </Text>
                  </td>
                  <td>
                    <Text size="sm" fw={500}>
                      <NumberFormatter size="sm" prefix="$ " value={subTotal} thousandSeparator />
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} style={{ textAlign: 'right' }}>
                    Tax 15%
                  </td>
                  <td>
                    <NumberFormatter prefix="$ " size="sm" value={tax} thousandSeparator />
                  </td>
                </tr>
                <tr>
                  <td colSpan={4} style={{ textAlign: 'right' }}>
                    <Text size="sm" fw={500}>
                      Total Amount
                    </Text>
                  </td>
                  <td>
                    <Text size="sm" fw={500}>
                      <NumberFormatter size="sm" prefix="$ " value={totalAmount} thousandSeparator />
                    </Text>
                  </td>
                </tr>
              </tbody>
            </Table>

            <h4>Delivery, payment and terms:</h4>
            <div className={css.terms}>
              <table>
                <tbody>
                  {Object.entries(rfqOptions).map(([name, value]) => {
                    const { description, selected } = settings.get(name, value)
                    return (
                      <tr key={name}>
                        <td>
                          <b>{description}</b>
                        </td>
                        <td>
                          <div>{selected?.label}</div>
                          <div className={css.desc}>{selected?.description}</div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </Preload>
  )
}

export default OrderPreview
