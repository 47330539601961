import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { IconChevronDown } from '@tabler/icons-react'

import css from './Nav.module.css'

const NavList = ({ list = [], className }) => {
  return (
    <ul className={cn(css.list, className)}>
      {list.map(({ label, Icon, to, children }, key) => (
        <li key={key + label}>
          {to ? (
            <NavLink to={to}>
              <Icon stroke={1} size={'18px'} />
              {label}
            </NavLink>
          ) : (
            <div className={css.tab}>
              <Icon stroke={1} size={'18px'} />
              {label}
              <IconChevronDown stroke={1} size={'18px'} />
            </div>
          )}

          {children && <NavList className={css.children} list={children} />}
        </li>
      ))}
    </ul>
  )
}

export default NavList
