import cn from 'classnames'

import css from './Pagging.module.css'

const TablePagging = ({ rows, limit, page, setPage }) => {
  const totalPages = Math.ceil(rows.length / limit)
  const pages = Array.from({ length: totalPages }, (value, index) => index + 1)
  const total = rows.length

  const itemsFrom = page * limit
  let itemsTo = itemsFrom + limit
  itemsTo = itemsTo > total ? total : itemsTo

  const start = pages.slice(0, 2)
  const end = pages.slice(-2)

  let from = page - 2
  let range = 5

  if (page < 4) {
    from = 0
    range = 7
  }

  if (page > totalPages - 6) {
    from = totalPages - 7
    range = 7
  }

  const middle = pages.slice(from, from + range)

  const onUpdate = (nr) => {
    window.scrollTo(0, 0)
    setPage(nr - 1)
  }

  return (
    <footer className={css.footer}>
      {total < limit ? (
        <div>Total: {total}</div>
      ) : (
        <>
          <div>
            Showing {itemsFrom + 1} to {itemsTo} of <span>{total}</span>
          </div>
          <div className={css.pages}>
            Per page: {limit}
            <div className={css.numbers}>
              {page > 3 && (
                <>
                  {start.map((nr) => (
                    <button key={nr} onClick={() => onUpdate(nr)}>
                      {nr}
                    </button>
                  ))}
                  <span>...</span>
                </>
              )}

              {middle.map((nr) => (
                <button key={nr} className={cn(nr === page + 1 && css.active)} onClick={() => onUpdate(nr)}>
                  {nr}
                </button>
              ))}

              {page < totalPages - 5 && (
                <>
                  <span>...</span>
                  {end.map((nr) => (
                    <button key={nr} onClick={() => onUpdate(nr)}>
                      {nr}
                    </button>
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </footer>
  )
}

export default TablePagging
