const tpl = `
<!DOCTYPE html>
<html>

<head>
  <title dr-text="title">[Title]</title>
</head>

<body>

  <div class="page-container">
    <style>
      * {
        box-sizing: border-box;
      }

      .page-container {
        --gray-300: #d1d5db;
        --gray-400: #9ca3af;

        font-family: Arial, sans-serif;
        font-size: 12px;
        /* width: 900px; */
        padding: 1px;
      }

      .flex__8Henc {
        display: flex;
        align-items: center;
        gap: 1em;
      }

      .avatar__8Henc {
        border-radius: 50vh;
      }

      .PdfPreview_page__8Henc table {
        width: 100%;
        border-collapse: collapse;
        border: none;
        margin: 2em 0;
        page-break-inside: avoid;

        font-family: Arial, sans-serif;
        font-size: 12px;
        line-height: 1.6;
        color: #333;
      }

      .PdfPreview_page__8Henc td,
      .PdfPreview_page__8Henc th {
        border: solid 1px var(--gray-300);
        padding: 8px;
      }

      .PdfPreview_page__8Henc th {
        background-color: #f2f2f2;
        font-weight: bold;
        text-align: left;
      }

      .PdfPreview_page__8Henc b {
        font-weight: 600;
      }

      .PdfPreview_page__8Henc .PdfPreview_productCode__wSP1u {
        border: solid 1px var(--gray-300);
        border-radius: 50vh;
        display: inline-flex;
        padding: 0 0.75em;
        line-height: 1rem;
      }

      .PdfPreview_page__8Henc ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      .PdfPreview_desc__xyygz {
        color: var(--gray-400);
      }
    </style>
    <div class="PdfPreview_page__8Henc">
      <h1>Request For Quote (RFQ): #<span dr-text="_key">[RfqID]</span></h1>
      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Request For Quote</th>
            <th>#<span dr-text="_key">[RfqID]</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Order Date</td>
            <td><span dr-text="createdAt" dr-format="date">[Created at]</span></td>
          </tr>
          <tr>
            <td>Order Number</td>
            <td>RFQ #1903</td>
          </tr>
          <tr>
            <td>Date</td>
            <td>October 10, 2024</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Buyer Information</th>
            <th>Seller Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                <li class="flex__8Henc">

                  <img class="avatar__8Henc" src="https://www.tourmo.ai/hubfs/Favicon_red_brain.png"
                    alt="Logo FleetSync">
                  <b dr-text="customer.name">[Customer name]</b>
                </li>

                <li>Client ID: <span dr-text="customer._key">[Client ID]</span></li>
                <li><span dr-text="customer.billingAddress.line1">[billingAddress.line1]</span></li>
                <li>
                  <span dr-text="customer.billingAddress.city">[city]</span>, <span
                    dr-text="customer.billingAddress.state">[state]</span>,
                  <span dr-text="customer.billingAddress.postalCode">[postalCode]</span>
                </li>
                <li><span dr-text="customer.billingAddress.country">[country]</span></li>
                <li>Contact: <span dr-text="createdBy.firstName">[first name]</span> <span
                    dr-text="createdBy.lastName">[last name]</span>
                </li>
                <li>Email: <span dr-text="createdBy.email">[email]</span></li>
                <li>Website: <span dr-text="customer.website">[website]</span></li>
              </ul>
            </td>
            <td>
              <ul>
                <li class="flex__8Henc">
                  <b dr-text="account.name">[Account name]</b>
                </li>

                <li><span dr-text="account.hqLocation">[HQ Location]</span></li>

                <li>Contact: <span dr-text="createdBy.firstName">[first name]</span> <span
                    dr-text="createdBy.lastName">[last name]</span>
                </li>
                <li>Phone: <span dr-text="account.businessPhoneNumber">[Phone]</span></li>
                <li>Website: <span dr-text="account.website">[website]</span></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Buyer's Delivery Address </th>
            <th>Buyer's Billing Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                <li class="flex"><span dr-text="customer.name">[Customer name]</span></li>
                <li><span dr-text="customer.shippingAddress.line1">[Line 1]</span></li>
                <li>
                  <span dr-text="customer.shippingAddress.city">[City]</span>,
                  <span dr-text="customer.shippingAddress.state">[State]</span>,
                  <span dr-text="customer.shippingAddress.postalCode">[PostalCode]</span>
                </li>
                <li><span dr-text="customer.shippingAddress.country">[Country]</span></li>
              </ul>
            </td>
            <td>
              <ul>
                <li class="flex"><span dr-text="customer.name">[Customer name]</span></li>
                <li><span dr-text="customer.billingAddress.line1">[Line 1]</span></li>
                <li>
                  <span dr-text="customer.billingAddress.city">[City]</span>,
                  <span dr-text="customer.billingAddress.state">[State]</span>,
                  <span dr-text="customer.billingAddress.postalCode">[PostalCode]</span>
                </li>
                <li><span dr-text="customer.billingAddress.country">[Country]</span></li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Product Code</th>
            <th>Description</th>
            <th>Unit Price</th>
            <th style="text-align: center;">Quantity</th>
            <th style="text-align: right;">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr dr-each="basket">
            <td>
              <div class="PdfPreview_productCode__wSP1u" dr-text=".product.productCode">[Product Code]</div>
            </td>
            <td>
              <div dr-text=".product.description">[Description]</div>
            </td>
            <td>$ <span dr-text=".product.sellingPrice">[sellingPrice]</span></td>
            <td>
              <div style="text-align: center;" dr-text=".quantity">[Quantity]</div>
            </td>
            <td>
              <div style="text-align: right;">$ <span dr-text=".total">[Total]</span></div>
            </td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td colspan="4" style="text-align: right;"><b>Gross Amount</b></td>
            <td><b>$ <span dr-text="subTotal">[Gross]</span></b></td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: right;">Tax 15%</td>
            <td>$ <span dr-text="tax">[Tax]</span></td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: right;"><b>Total Amount</b></td>
            <td><b>$ <span dr-text="totalAmount">[Total]</span></b></td>
          </tr>
        </tfoot>
      </table>
      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Delivery, payment and terms</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          <tr dr-each="terms">
            <td>
              <div dr-text=".description">[Term]</div>
            </td>
            <td>
              <div dr-text=".selected.label">[Selected Label]</div>
              <div class="PdfPreview_desc__xyygz" dr-text=".selected.description">
                [Selected description]
              </div>
            </td>
          </tr>

          <tr>
            <td>General Terms and Conditions</td>
            <td><a href="https://www.precisionmanufacturing.com/terms">https://www.precisionmanufacturing.com/terms</a>
            </td>
          </tr>
          <tr>
            <td>Return Policy</td>
            <td><a
                href="https://www.precisionmanufacturing.com/returns">https://www.precisionmanufacturing.com/returns</a>
            </td>
          </tr>
          <tr>
            <td>Warranty Details</td>
            <td><a
                href="https://www.precisionmanufacturing.com/warranty">https://www.precisionmanufacturing.com/warranty</a>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th style="width: 50%;">Buyer Signature</th>
            <th>Seller Signature</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Name: ______________________<br>Title: ______________________<br><br><br>
              <div>Signature:</div><br><br><br>
              <div>Date: </div>
            </td>
            <td>Name: John Smith<br>Title: Sales Manager<br><br><br>
              <div>Signature:</div><br><br><br>
              <div>Date: </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</body>

</html>
`

export default tpl
