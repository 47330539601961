import get from 'lodash.get'
import set from 'lodash.set'
import { useForm, isEmail, isInRange } from '@mantine/form'
import { Stepper, Button, Group, TextInput, Textarea, Select, NumberInput } from '@mantine/core'

import css from './Form.module.css'
import { useEffect } from 'react'
/*

inputs = [
  {
    name: 'billingAddress.city',
    label: 'City'
    type: 'TextInput'
    optional: true
  }
]

*/

//---------------------------------------------------------------------------
const Header = ({ label }) => <h2>{label}</h2>

//---------------------------------------------------------------------------
const formInputs = { TextInput, Textarea, Select, NumberInput, Header }
const formValidators = { isInRange, isEmail }

//---------------------------------------------------------------------------
const Form = ({ data, inputs, onSubmit, children }) => {
  let initialValues = {}
  let validatation = {}

  inputs.forEach(({ name, validate }) => {
    if (name) {
      const value = get(data, name)
      set(initialValues, name, value || '')
    }
    if (validate) {
      const { type, message, ...values } = validate
      const validateFn = formValidators[type]

      if (validateFn) set(validatation, name, validateFn(values, message))
    }
  })

  const form = useForm({
    mode: 'uncontrolled',
    initialValues,
    validate: validatation,
  })

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      {inputs.map((item, key) => (
        <FormItem form={form} {...item} key={key} />
      ))}
      {children}
    </form>
  )
}

//---------------------------------------------------------------------------
const FormItem = ({ header, form, name, label, description, placeholder, type, optional, data }) => {
  if (header) {
    return <Header label={header} />
  }

  const Input = formInputs[type] || TextInput

  return (
    <div className={css.row}>
      <Input
        required={!optional} //
        label={label}
        description={description}
        data={data}
        placeholder={placeholder || label}
        key={form.key(name)}
        {...form.getInputProps(name)}
      />
    </div>
  )
}

//---------------------------------------------------------------------------
export default Form
