import { useParams } from 'react-router-dom'
import { Button, Text, Badge, Avatar } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'

import Preload, { usePreload } from '../../../components/PreLoad/PreLoad'
import { PageHeader } from '../../../components/page-header'
import { usePdf } from 'common/hooks/usePdf'

import Conversations from '../../../components/Conversations/Conversations'
import CompanyAside from '../../../components/CompanyAside/CompanyAside'

import css from './RequestPage.module.css'

import sales from 'api/sales'

const breadcrumbs = [
  { label: 'Order to cash', href: '/' }, //
  { label: 'Request for quote', href: '/o2c/requests-for-quote' },
  { label: 'Enquiry' },
]
const RequestPage = () => {
  const { id } = useParams()
  const { download, isLoading } = usePdf()

  const resolve = usePreload(async () => {
    const enquiry = await sales.getEnquiry(id)
    const [first] = enquiry?.conversation

    const basket =
      enquiry.basket ||
      first?.result?.reduce(
        (
          buf, //
          { suggestedProducts, quantity }
        ) => [...buf, ...suggestedProducts.map((product, key) => ({ product, quantity: !key ? quantity : 0 }))],
        []
      )

    return { ...enquiry, basket, haveBasket: !!enquiry.basket }
  })

  const { data: enquiry, onRetry } = resolve
  const { status, conversation = [], createdBy, haveBasket } = enquiry || {}

  const { firstName, lastName, email } = createdBy || {}
  const myTurn = status === 'received'
  const canCreateOrder = conversation.length > 2

  const onSubmit = (message, selected, rfqOptions) => {
    const basket = Object.values(selected).filter(({ quantity }) => quantity)
    onRetry(() => sales.replyToEnquiry(id, { message, basket, rfqOptions }))
  }

  const onCreateOrder = (message, selected, rfqOptions) => {
    const basket = Object.values(selected).filter(({ quantity }) => quantity)
    onRetry(() => sales.createOrder(id, { message, createdBy: enquiry.createdBy, basket, rfqOptions }))
  }

  return (
    <Preload {...resolve} className="container2">
      <PageHeader title={'Requests for quote - ID' + id} breadcrumbs={breadcrumbs} />

      <div className={css.layout}>
        <aside>
          <CompanyAside {...enquiry} />
        </aside>

        <section>
          {haveBasket && (
            <div>
              <Button
                onClick={() => download(enquiry, 'rfq', { title: 'Request for quote: #' + id })}
                disabled={isLoading}
                variant="default"
                leftSection={<IconDownload size={12} />}
                size="compact-sm"
                radius="sm"
              >
                <Text size="xs">Download PDF</Text>
              </Button>
            </div>
          )}

          <div className={css.header}>
            <div className="flex">
              <Avatar variant="outline" color="blue" name={firstName + ' ' + lastName} />

              <div>
                <div>
                  {firstName} {lastName}
                </div>
                <small>{email}</small>
              </div>
            </div>

            <h4>
              <div>
                Status:&nbsp;&nbsp;
                <Badge color={status === 'received' ? 'orange' : 'blue'} size="lg">
                  {status}
                </Badge>
              </div>
            </h4>
          </div>
          <Conversations enquiry={enquiry} onSubmit={myTurn && onSubmit} onCreateOrder={canCreateOrder && onCreateOrder} canEdit />
        </section>
      </div>
    </Preload>
  )
}

export default RequestPage
